<style scoped>
	div {
		box-sizing: border-box;
	}
	img {
		object-fit: cover;
	}
	
	.top-tab {
		width: 100%;
		min-width: 1320px;
		height: 50px;
		background-color: #515C76;
		z-index: 999;
		opacity: .8;
		margin: -50px auto 0;
	}
	
	.tabInner {
		width: 1320px;
		padding: 0 315px;
		height: 100%;
		color: #fff;
		font-size: 16px;
		font-weight: bold;
		opacity: 1;
		margin: 0 auto;
	}
	
	.tabInner>div {
		cursor: pointer;
	}
	
	.tab-active {
		position: relative;
	}
	
	.tab-active::after {
		content: "";
		position: absolute;
		bottom: -13px;
		left: 50%;
		width: 30px;
		height: 2px;
		background: #fff;
		transform: translateX(-50%);
	}
	
	/*  */
	.content {
		width: 1320px;
		margin: 0 auto;
	}
	
	.top-title {
		margin-top: 130px;
		padding: 0 30px;
		text-align: center;
	}
	
	.title-text1 {
		font-size: 31px;
		font-weight: bold;
		color: #222;
	}
	
	.title-text2 {
		color: #666;
		font-size: 15px;
		font-weight: bold;
		margin: 60px 0 85px;
	}
	
	.box1 {
		width: 100%;
		padding: 0 150px;
	}
	
	.box1-inner {
		position: relative;
	}
	
	.box1-inner>div {
		position: absolute;
		padding: 0 20px;
		width: 240px;
		height: 105px;
		box-shadow: 0 0 40px #FAFAFA;
		top: 100px;
		left: 50%;
		transform: translateX(-50%);
		font-size: 13px;
		color: #222;
	}
	
	.box1-inner2 {
		flex-direction: column;
		color: #222;
		font-size: 15px;
	}
</style>
<template>
	<div>
		<Top :topTitle="topTitle"></Top>
		<div class="top-tab">
			<div class="tabInner flex-around flex-y-center">
				<div :class="{'tab-active': urlName == item.path}" v-for="(item, index) in tabList" :key="index" @click="toPath(item.path)">
					{{item.name}}
				</div>
			</div>
		</div>
		<router-view></router-view>
	</div>
</template>

<script>
	import Top from "../../../components/top.vue";
	export default {
		name: "product",
		data() {
			return {
				tabList: [
					{name: "产品中心", path: "healthcare"}, 
					//{name: "关节健康", path: "jointhealth"}
				],
				topTitle: "我们提供的服务",
				urlName: "applet",
			}
		},
		created() {
			// this.getCate();
			this.urlName = this.$route.name;
		},
		watch: {
			$route(to) {
				this.urlName = to.name;
			}
		},
		methods: {
			getCate(){
				this.http({
					method: "get",
					url: this.api.getServer,
					data: {}
				}).then(res => {
					this.tabList = res.data;
				})
			},
			toPath(name) {
				this.$router.push(`/product/${name}`);
			}
		},
		components: {Top}
	}
</script>


